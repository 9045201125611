import React, { useState } from "react";
import PropTypes from "prop-types";
import LayoutClean from "../Layouts/LayoutClean.js";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { HttpPost, HttpUpload } from "../HTTP.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { copyTextToClipboard } from "../Helpers/copyToClipboard.js";
import FormJson from "../Components/FormJson.js";
import { Button } from "../Components/LinkButton.js";
import Loading from "../Components/LoadingIcon.js";
import { selfUrl, webLink } from "../appConfig.js";
import Styles from "./RecipientRequestForm.module.scss";

const RecipientRequestForm = ({ params }) => {
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);

  const { data: formJson } = useSWR(`recipientRequestForm`);
  const { data: formFilesCategories } = useSWR(`recipientRequestFormFiles`);
  const [isLoading, setLoading] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  //console.log({ formFilesCategories, formJson });

  const saveData = (data, files) => {
    return new Promise((resolve, reject) => {
      HttpPost(`recipientRequestForm`, data)
        .then((resp) => {
          const errorCode = resp && resp.error_code;

          if (errorCode !== 200) {
            reject(errorCode ? errorCode : resp.error);
            return;
          }

          // step 2, upload the files...
          const regId = resp?.response?.Data;
          HttpUpload(`recipientRequestFormFiles/${regId}`, files)
            .then((_uploadResp) => {
              // console.log("upload", { regId, resp });
            })
            .catch((err) => {});

          resolve(resp);
        })
        .catch((err) => {});
    });
  };

  const handleSubmit = (data, files) => {
    // do submit data to backend...
    setLoading(true);

    saveData(data, files)
      .then(() => {
        MySwal.fire({
          title: t("recipient-request-form-saved"),
          icon: "success",
          timer: 2000,
          toast: true,
          position: "center",
          timerProgressBar: true,
          showConfirmButton: false,
        });

        setLoading(false);
        setSubmitted(true);
      })
      .catch((errorCode) => {
        MySwal.fire({
          title: t("frm-notification-save-error"),
          text: t(`recipient-request-form-save-error-${errorCode}`),
          icon: "error",
          timer: 30000,
          position: "center",
          timerProgressBar: true,
          showConfirmButton: true,
        });
        setLoading(false);
      });
  };

  const handleCopyLink = (link) => {
    copyTextToClipboard(link)
      .then(() => {
        MySwal.fire({
          title: t("clipboard-copied"),
          icon: "success",
          timer: 2000,
          toast: true,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      })
      .catch(() => {});
  };

  if (!formJson) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          columnGap: "1em",
        }}
      >
        <Loading />
        <div>{t("frm-loading-form-data")}</div>
      </div>
    );
  }

  if (isSubmitted) {
    const editLink = `${selfUrl}dotaznik-editace`;
    return (
      <LayoutClean pageTitle={t("recipient-request-form")}>
        <div className={Styles.wrap}>
          <h1 className="title">{formJson.title}</h1>
          <section>{t("recipient-request-form-thank-you")}</section>

          <section className={Styles.linkWrap}>
            <div className={Styles.linkBoxWrap}>
              <a href={editLink} target="_blank" rel="noreferrer">
                <code style={{ padding: "1em", lineHeight: "3em" }}>
                  {editLink}
                </code>
              </a>

              <Button
                onClick={() => {
                  handleCopyLink(editLink);
                }}
              >
                <i className="fa-regular fa-copy" />
                {t("clipboard-copy")}
              </Button>
            </div>

            <div className="note">{t("recipient-request-form-link-note")}</div>
          </section>

          <div className={Styles.successBtn}>
            <Button
              onClick={() => {
                window.location.href = webLink;
              }}
            >
              {t("header-backToWeb")}
            </Button>
          </div>
        </div>
      </LayoutClean>
    );
  }

  return (
    <LayoutClean pageTitle={t("recipient-request-form")}>
      <div className={Styles.wrap}>
        <section className={Styles.preFormText}>
          <h1 className="title">{formJson.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: formJson.description }}></div>
        </section>

        <section>
          <FormJson
            blueprint={formJson.items}
            allowFiles={true}
            fileCategories={formFilesCategories || null}
            allowedFileTypes={[
              "image/jpeg",
              "image/png",
              "image/x-ms-bmp",
              "application/pdf",
            ]}
            allowedFileSize={5 * 1024 * 1024}
            onSubmit={handleSubmit}
            disableSubmit={isLoading}
          />
        </section>
      </div>
    </LayoutClean>
  );
};

RecipientRequestForm.propTypes = {
  params: PropTypes.object,
};

export default RecipientRequestForm;
